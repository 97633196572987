<template>
  <div class="content">
    <template>
      <v-card
        :style="
          $vuetify.breakpoint.xs
            ? 'margin: 4px'
            : 'margin: 0 18px 0 18px'
        "
      >
        <base-loading v-if="loading" />
        <div v-if="!loading">
          <crud-list
            v-model="produtores"
            :headers="headers"
            :actions="this.$store.state.user.roles[0].slug !== 'tecnico' ? actions : []"
            :slots="['item.telefones', 'item.cpf', 'item.nome']"
            :show-expand="true"
            :permission="'list_produtor'"
            :sort-by="['nome_razao_social']"
            :sort-desc="[false]"
            export-pdf-xlsx
          >
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length">
                <v-card-title>
                  <span style="font-size: 14px; color: rgba(0, 0, 0, 0.87); font-weight: bold"> Fazendas Vinculadas</span>
                </v-card-title>
                <v-list-item
                  v-for="fazenda, index in item.fazendas"
                  :key="fazenda.razao_social"
                  style="min-height: 20px; max-height: 52px;  padding: 0px;  padding-top: 0px;"
                >
                  <v-list-item-content>
                    <v-card
                      elevation="2"
                      outlined
                      class="my-n1"
                    >
                      <v-row>
                        <v-card-actions class="ml-3 my-0 py-0">
                          <v-col class="my-0 py-1">
                            <v-row class="my-0 py-0">
                              <v-btn
                                v-if="$store.getters.hasPermission('ativar_fazenda') && $store.state.user.roles[0].slug !== 'tecnico'"
                                icon
                                small
                                class="text-none text-white"
                                title="Remover Fazenda do Produtor"
                                @click="
                                  DELETE_CAD_FAZENDA({
                                    id: fazenda.cadastro.id,
                                    produtorId: item.id,
                                    fazendaId: fazenda.id,
                                  })
                                "
                              >
                                <v-icon color="red darken-3">
                                  mdi-trash-can-outline
                                </v-icon>
                              </v-btn>
                              <div v-if="$store.getters.hasPermission('ativar_produtor') && $store.state.user.roles[0].slug !== 'tecnico'">
                                <v-btn
                                  v-if="!fazenda.cadastro.ativo"
                                  icon
                                  small
                                  class="text-none text-white"
                                  title="Ativar Fazenda do Produtor"
                                  @click="ATIVAR_CAD_FAZENDA({
                                    id: fazenda.cadastro.id,
                                    produtorId: item.id,
                                    fazendaId: fazenda.id,
                                  })"
                                >
                                  <v-icon
                                    color="orange lighten-1"
                                  >
                                    mdi-check-circle-outline
                                  </v-icon>
                                </v-btn>
                                <v-btn
                                  v-if="fazenda.cadastro.ativo"
                                  icon
                                  small
                                  class="text-none text-white"
                                  title="Inativar Fazenda do Produtor"
                                  @click="INATIVAR_CAD_FAZENDA({
                                    id: fazenda.cadastro.id,
                                    produtorId: item.id,
                                    fazendaId: fazenda.id,
                                  })"
                                >
                                  <v-icon
                                    color="red lighten-1"
                                  >
                                    mdi-close-circle-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                              <v-chip
                                v-if="!fazenda.cadastro.ativo"
                                color="red lighten-1"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Inativo</b>
                              </v-chip>
                              <v-chip
                                v-if="fazenda.cadastro.ativo"
                                color="green lighten-2"
                                class="mb-2 pl-4 pr-4 my-1"
                                x-small
                                text-color="white"
                              >
                                <b>Ativo</b>
                              </v-chip>
                            </v-row>
                          </v-col>
                        </v-card-actions>
                        <v-card
                          elevation="0"
                          :disabled="!fazenda.cadastro.ativo"
                          class="my-3 py-0"
                        >
                          <v-card-text class="mb-2 pl-2 pr-2 my-2 py-1">
                            <div>
                              <span style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold">{{ fazenda.inscricao_estadual ? `${fazenda.razao_social} (${fazenda.inscricao_estadual})` : `${fazenda.razao_social}` }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              >Código Fornecedor: </span><span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)">{{ fazenda.cadastro.cod_produtor_fornecedor }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              > Código Cliente: </span><span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)"> {{ fazenda.cadastro.cod_produtor_cliente }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              > Unidade de negócio: </span><span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)"> {{ fazenda.unidade_negocio.filial }}</span>
                              <span
                                class="ml-3"
                                style="font-size: 12px; color: rgba(0, 0, 0, 0.87); font-weight: bold"
                              >
                                Técnico:
                              </span>
                              <span style="font-size: 12px; color: rgba(0, 0, 0, 0.87)">
                                {{ item.tecnicos[index]?.pessoafisica ? `${item.tecnicos[index]?.pessoafisica.nome} (${item.tecnicos[index].pessoafisica.cpf})` : '' }}
                              </span>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-row>
                    </v-card>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-show="cadForm.produtor_id === item.id">
                  <v-list-item-content>
                    <produtor-fazenda-form
                      v-model="cadForm"
                      @produtor-fazenda="ATUALIZA_PROD_LIST"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-list-item
                  v-if="$store.getters.hasPermission('ativar_produtor') && $store.state.user.roles[0].slug !== 'tecnico'"
                  style="padding-left: 0;"
                >
                  <v-list-item-content>
                    <div class="my-n1">
                      <v-btn
                        dark
                        small
                        color="success"
                        elevation="3"
                        rounded
                        title="Inclui uma nova Fazenda."
                        @click="mostraForm(item.id)"
                      >
                        <v-icon
                          small
                          dark
                        >
                          mdi-plus
                        </v-icon>
                        Incluir uma nova Fazenda
                      </v-btn>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </td>
            </template>
            <template
              v-slot:btnCadastro
            >
              <v-col
                v-if="$store.getters.hasPermission('inserir_produtor') && $store.state.user.roles[0].slug !== 'tecnico'"
              >
                <v-btn
                  block
                  class="text-none text-white"
                  color="primary"
                  title="Cadastrar"
                  rounded
                  @click="$router.push({ path: 'produtores/cadastrar' })"
                >
                  <v-icon
                    dark
                    left
                  >
                    mdi-file-plus
                  </v-icon>
                  Cadastrar
                </v-btn>
              </v-col>
            </template>
            <template v-slot:[`item.telefones`]="{ item }">
              <div
                v-for="(telefone, index) in item.telefones"
                :key="index"
              >
                <span v-if="telefone">
                  {{ `${index + 1}:  ${telefone}` }}
                  <br>
                </span>
              </div>
            </template>
          </crud-list>
        </div>
      </v-card>
    </template>
  </div>
</template>
<script>
  import ProdutorFazendaForm from './ProdutorFazendaForm'
  import ProdutoresStore, { BOOTSTRAP, INATIVAR_PRODUTOR, ATIVAR_CAD_FAZENDA, INATIVAR_CAD_FAZENDA, ATUALIZA_PROD_LIST, DELETE_CAD_FAZENDA } from '@/store/modules/produtores'
  import { mapGetters, mapState, mapActions } from 'vuex'
  import store from '@/store'
  import { sortDateTime } from '../../utils/sorts'

  export default {
    components: {
      ProdutorFazendaForm,
    },
    data () {
      return {
        actions: [
          {
            title: 'Editar produtor',
            color: 'info darken-3',
            click: item => this.editarProdutor(item),
            icon: 'mdi-pencil',
            permission: 'editar_produtor',
          },
          {
            title: 'Excluir Produtor',
            color: 'red darken-3',
            click: item => this.INATIVAR_PRODUTOR(item),
            icon: 'mdi-trash-can-outline',
            permission: 'excluir_produtor',
          },
        ].filter(action => store.getters.hasPermission(action.permission)),
        cadForm: { produtor_id: null, cod_produtor_fornecedor: null, cod_produtor_cliente: null, fazenda_id: null },
        headers: [
          { text: '', sortable: true, floatingfilter: false, groupable: false, value: 'data-table-expand' },
          { text: '', sortable: true, floatingfilter: false, groupable: false, value: 'actions', width: '40px' },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'CPF/CNPJ', value: 'cpf_cnpj', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'Nome/Razão Social', value: 'nome_razao_social', width: 350, report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'RG', value: 'rg', width: 'auto', report: true },
          { align: 'center', sortable: true, floatingfilter: true, groupable: false, text: 'Nascimento', value: 'data_nascimento', width: 'auto', sort: sortDateTime, report: true },
          { align: 'left', sortable: true, floatingfilter: false, groupable: true, text: 'Sexo', value: 'sexo', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'Email', value: 'email', width: 'auto', report: true, styleReport: { cellWidth: 65 } },
          { align: 'left', sortable: false, floatingfilter: false, groupable: false, text: 'Telefones', value: 'telefones', width: 'auto', report: true, styleXLSX: { type: 'telefone' } },
          { align: 'left', sortable: true, floatingfilter: false, groupable: false, text: 'Logradouro', value: 'endereco.logradouro', width: 'auto' },
          { align: 'left', sortable: true, floatingfilter: false, groupable: false, text: 'Número', value: 'endereco.numero', width: 'auto' },
          { align: 'left', sortable: true, floatingfilter: false, groupable: false, text: 'Complemento', value: 'endereco.complemento', width: 'auto' },
          { align: 'left', sortable: true, floatingfilter: false, groupable: false, text: 'Bairro', value: 'endereco.bairro', width: 'auto' },
          { align: 'left', sortable: true, floatingfilter: true, groupable: true, text: 'Cidade', value: 'endereco.cidade.nome', width: 'auto', report: true },
          { align: 'center', sortable: true, floatingfilter: true, groupable: true, text: 'Estado', value: 'endereco.estado.sigla', width: 'auto', report: true },
          { align: 'left', sortable: true, floatingfilter: true, groupable: false, text: 'CEP', value: 'endereco.cep', width: 'auto' },
        ],
      }
    },
    computed: {
      ...mapState('produtores', ['loading', 'produtores']),
      ...mapGetters(['hasPermission']),
      isNotifica () {
        return this.$user.get().role === 'gestor'
      },
    },
    async created () {
      if (!this.$store.state.produtores) { this.$store.registerModule('produtores', ProdutoresStore) }
      this.BOOTSTRAP()
    },
    methods: {
      ...mapActions('produtores', [BOOTSTRAP, INATIVAR_PRODUTOR, ATIVAR_CAD_FAZENDA, INATIVAR_CAD_FAZENDA, ATUALIZA_PROD_LIST, DELETE_CAD_FAZENDA]),
      handleFilterInput (item) {
        item.checked = item.value || (item.value && item.checked)
      },
      mostraForm (id) {
        this.cadForm = {
          produtor_id: id,
          cod_produtor_fornecedor: null,
          cod_produtor_cliente: null,
          fazenda_id: null,
        }
      },
      editarProdutor ({ id }) {
        this.$router.push({
          path: `/${this.$user.get().role}/produtores/alterar`,
          query: { id },
        })
      },
    },
  }
</script>
